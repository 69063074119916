<template>
  <div>
    <div class="invets">
      <div class="invets_left">
        <div class="titles_s">
          <div class="titles_z">
            <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
            <span>固定资产投资月度累计完成情况：投资总额</span>
          </div>
          <div class="text-right">
            <span>年份 </span>
            <el-select
              v-model="yearValue"
              placeholder="请选择"
              style="width: 100px"
              size="small"
              @change="changeYear"
            >
              <el-option
                v-for="item in yearList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div ref="in_L" class="mt-3" style="width: 100%; height: 350px"></div>
      </div>
      <div class="invets_right">
        <div class="titles">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          社会消费品零售总额：当期值
        </div>

        <div
          ref="in_R"
          style="width: 100%; height: 350px; margin-top: 45px"
        ></div>
      </div>
    </div>
    <div class="cy">
      <div class="titles">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        固定资产投资累计完成情况：分产业
      </div>
      <div>
        <div class="mt-3 row justify-content-between">
          <div>
            <el-button-group>
              <el-button
                size="medium"
                :type="active ? 'primary' : 'pain'"
                @click="active = true"
                >月度</el-button
              >
              <el-button
                size="medium"
                :type="!active ? 'primary' : 'pain'"
                @click="active = false"
                >年度</el-button
              >
            </el-button-group>
          </div>
          <div class="row align-items-center" v-show="active">
            <div style="color: #333; margin-right: 5px">年份</div>
            <el-select
              @change="year_change"
              v-model="yearValue1"
              placeholder="请选择"
              style="width: 100px"
              size="medium"
            >
              <el-option
                v-for="item in yearList1"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          ref="in_L1"
          v-show="active"
          class="mt-3"
          style="width: 100%; height: 350px"
        ></div>
        <div
          ref="in_L2"
          v-show="!active"
          class="mt-3"
          style="width: 100%; height: 350px"
        ></div>
      </div>
    </div>
    <div class="hy">
      <div class="titles">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        固定资产投资完成情况：分行业
      </div>
      <div class="mt-2 row align-items-center">
        <div class="titles">固定资产投资额累计增长(%)</div>
        <div class="row align-items-center ml-3">
          <el-cascader
            style="width: 250px"
            ref="cascader"
            size="small"
            @visible-change="companyChange"
            v-model="optionsList"
            :options="options"
            :props="{
              limit: 8,
              multiple: true,
              checkStrictly: true,
              emitPath: false,
              value: 'industry_code',
              label: 'industry_name',
              children: 'children',
            }"
            collapse-tags
            :checkStrictly="true"
            :show-all-levels="false"
          ></el-cascader>
          <span class="ml-3" style="color: #999"> 最多选择8个行业 </span>
        </div>
      </div>
      <div class="mt-3" ref="in_H" style="width: 100%; height: 350px"></div>
    </div>
  </div>
</template>

<script>
import {
  get_year,
  gross_assets,
  social_consumer_goods,
  gross_assets_industry,
  get_year_month,
  gross_assets_industry_year,
  gross_industry_level,
  gross_industry,
} from "@/api.js";

export default {
  data() {
    return {
      active: true,
      yearList: [],
      yearValue: "",

      yearList1: [],
      yearValue1: "",
      maxCount: 8,
      optionsList: ["gbA", "gbC", "gbG", "gbK"], //类型
      options: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
            {
              value: "daohang",
              label: "导航",
              children: [
                {
                  value: "cexiangdaohang",
                  label: "侧向导航",
                },
                {
                  value: "dingbudaohang",
                  label: "顶部导航",
                },
              ],
            },
          ],
        },
        {
          value: "zujian",
          label: "组件",
          children: [
            {
              value: "basic",
              label: "Basic",
              children: [
                {
                  value: "layout",
                  label: "Layout 布局",
                },
                {
                  value: "color",
                  label: "Color 色彩",
                },
                {
                  value: "typography",
                  label: "Typography 字体",
                },
                {
                  value: "icon",
                  label: "Icon 图标",
                },
                {
                  value: "button",
                  label: "Button 按钮",
                },
              ],
            },
            {
              value: "form",
              label: "Form",
              children: [
                {
                  value: "radio",
                  label: "Radio 单选框",
                },
                {
                  value: "checkbox",
                  label: "Checkbox 多选框",
                },
                {
                  value: "input",
                  label: "Input 输入框",
                },
                {
                  value: "input-number",
                  label: "InputNumber 计数器",
                },
                {
                  value: "select",
                  label: "Select 选择器",
                },
                {
                  value: "cascader",
                  label: "Cascader 级联选择器",
                },
                {
                  value: "switch",
                  label: "Switch 开关",
                },
                {
                  value: "slider",
                  label: "Slider 滑块",
                },
                {
                  value: "time-picker",
                  label: "TimePicker 时间选择器",
                },
                {
                  value: "date-picker",
                  label: "DatePicker 日期选择器",
                },
                {
                  value: "datetime-picker",
                  label: "DateTimePicker 日期时间选择器",
                },
                {
                  value: "upload",
                  label: "Upload 上传",
                },
                {
                  value: "rate",
                  label: "Rate 评分",
                },
                {
                  value: "form",
                  label: "Form 表单",
                },
              ],
            },
            {
              value: "data",
              label: "Data",
              children: [
                {
                  value: "table",
                  label: "Table 表格",
                },
                {
                  value: "tag",
                  label: "Tag 标签",
                },
                {
                  value: "progress",
                  label: "Progress 进度条",
                },
                {
                  value: "tree",
                  label: "Tree 树形控件",
                },
                {
                  value: "pagination",
                  label: "Pagination 分页",
                },
                {
                  value: "badge",
                  label: "Badge 标记",
                },
              ],
            },
            {
              value: "notice",
              label: "Notice",
              children: [
                {
                  value: "alert",
                  label: "Alert 警告",
                },
                {
                  value: "loading",
                  label: "Loading 加载",
                },
                {
                  value: "message",
                  label: "Message 消息提示",
                },
                {
                  value: "message-box",
                  label: "MessageBox 弹框",
                },
                {
                  value: "notification",
                  label: "Notification 通知",
                },
              ],
            },
            {
              value: "navigation",
              label: "Navigation",
              children: [
                {
                  value: "menu",
                  label: "NavMenu 导航菜单",
                },
                {
                  value: "tabs",
                  label: "Tabs 标签页",
                },
                {
                  value: "breadcrumb",
                  label: "Breadcrumb 面包屑",
                },
                {
                  value: "dropdown",
                  label: "Dropdown 下拉菜单",
                },
                {
                  value: "steps",
                  label: "Steps 步骤条",
                },
              ],
            },
            {
              value: "others",
              label: "Others",
              children: [
                {
                  value: "dialog",
                  label: "Dialog 对话框",
                },
                {
                  value: "tooltip",
                  label: "Tooltip 文字提示",
                },
                {
                  value: "popover",
                  label: "Popover 弹出框",
                },
                {
                  value: "card",
                  label: "Card 卡片",
                },
                {
                  value: "carousel",
                  label: "Carousel 走马灯",
                },
                {
                  value: "collapse",
                  label: "Collapse 折叠面板",
                },
              ],
            },
          ],
        },
        {
          value: "ziyuan",
          label: "资源",
          children: [
            {
              value: "axure",
              label: "Axure Components",
            },
            {
              value: "sketch",
              label: "Sketch Templates",
            },
            {
              value: "jiaohu",
              label: "组件交互文档",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.$loadingShow();
    this.get_in_R();
    this.get_in_H();
    this.get_in_HY();
  },
  created() {
    this.get_year(); // 获取年份列表
    this.get_year_month();
  },
  computed: {},
  watch: {
    active() {
      this.get_in_L2();
    },

    optionsList(newVal, oldVal) {
      if (newVal.length > 8) {
        this.$message({
          message: "最多只支持选择8项",
          duration: 1500,
          type: "warning",
        });
        this.$nextTick(() => {
          this.optionsList = oldVal;
        });
      }
      // else {
      //   this.$nextTick(() => {
      //     this.optionsList = oldVal;
      //   });
      // }
    },
  },
  filters: {},
  methods: {
    get_year() {
      get_year().then(({ data }) => {
        this.yearList = data.year;
        this.yearValue = data.year[0];
        this.get_in_L();
      });
    },
    get_year_month() {
      get_year_month().then(({ data }) => {
        this.yearList1 = data.year;
        this.yearValue1 = data.year[0];
        this.get_in_L1();
      });
    },

    changeYear() {
      this.get_in_L();
    },

    year_change() {
      this.get_in_L1();
    },

    get_in_L() {
      gross_assets({ year: this.yearValue }).then(({ data }) => {
        let month = data.month;
        let data1 = data.投资完成额;
        let data2 = data.投资完成额同比增长;
        var option = {
          color: ["#529EE2", "#FAD33F"],
          tooltip: {
            trigger: "axis",
            // axisPointer: {
            //   type: "cross",
            //   crossStyle: {
            //     color: "#999",
            //   },
            // },
          },
          grid: {
            left: "3%",
            right: "3%",
            bottom: "3%",
            containLabel: true,
          },
          legend: {},
          xAxis: [
            {
              type: "category",
              data: month,
              axisPointer: {
                type: "shadow",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "单位：亿元",
            },
            {
              type: "value",
              min: 0,
              max: 50,
              interval: 10,
              axisLabel: {
                formatter: "{value}%",
              },
            },
          ],
          series: [
            {
              name: "投资完成额(亿元)",
              type: "bar",
              // tooltip: {
              //   valueFormatter: function (value) {
              //     return value;
              //   },
              // },
              data: data1,
            },

            {
              name: "投资完成额同比增长(%)",
              type: "line",
              yAxisIndex: 1,
              // tooltip: {
              //   valueFormatter: function (value) {
              //     return value + "%";
              //   },
              // },
              data: data2,
            },
          ],
        };
        this.$echarts.init(this.$refs.in_L).setOption(option);
      });
    },
    get_in_R() {
      social_consumer_goods().then(({ data }) => {
        let month = data.month;
        let dataList = data.data;
        let color = ["#529EE2", "#F7882B", "#3EBB9F"];
        let datas = Object.keys(dataList);
        let serve = Object.values(dataList);
        let series = [];
        for (let i = 0; i < datas.length; i++) {
          series.push({
            name: datas[i],
            type: "line",
            areaStyle: {
              //区域填充渐变颜色
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: color[i], // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#fff", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              focus: "series",
            },
            data: serve[i],
          });
        }
        var option = {
          color: color,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              label: {
                backgroundColor: "#6a7985",
                formatter: function (params) {
                  return params.value + "当期值";
                },
              },
            },
          },
          legend: {},
          grid: {
            left: "3%",
            right: "3%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: month,
            },
          ],
          yAxis: [
            {
              name: "单位：亿元",
              type: "value",
            },
          ],
          series: series,
        };
        this.$echarts.init(this.$refs.in_R).setOption(option);
      });
    },

    get_in_L1() {
      gross_assets_industry({ year: this.yearValue1 }).then(({ data }) => {
        let month = data.month;
        let data1 = data.第一产业;
        let data2 = data.第二产业;
        let data3 = data.第三产业;
        var option = {
          color: ["#FAD33F", "#F7882B", "#529EE2"],
          tooltip: {
            trigger: "axis",
            // axisPointer: {
            //   type: "cross",
            //   crossStyle: {
            //     color: "#999",
            //   },
            // },
          },
          grid: {
            left: "3%",
            right: "3%",
            bottom: "3%",
            containLabel: true,
          },
          legend: {},
          xAxis: [
            {
              type: "category",
              data: month,
              axisPointer: {
                type: "shadow",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "单位：亿元",
            },
          ],
          series: [
            {
              name: "第一产业",
              type: "bar",
              // tooltip: {
              //   valueFormatter: function (value) {
              //     return value;
              //   }
              // },
              data: data1,
            },
            {
              name: "第二产业",
              type: "bar",
              // tooltip: {
              //   valueFormatter: function (value) {
              //     return value;
              //   }
              // },
              data: data2,
            },
            {
              name: "第三产业",
              type: "bar",
              // tooltip: {
              //   valueFormatter: function (value) {
              //     return value + ' ml';
              //   }
              // },
              data: data3,
            },
          ],
        };
        this.$echarts.init(this.$refs.in_L1).setOption(option);
      });
    },

    get_in_L2() {
      this.$loadingShow();
      gross_assets_industry_year().then(({ data }) => {
        let year = data.year;
        let data1 = data.第一产业;
        let data2 = data.第二产业;
        let data3 = data.第三产业;
        let data4 = data.第一产业同比增长;
        let data5 = data.第二产业同比增长;
        let data6 = data.第三产业同比增长;
        var option = {
          color: [
            "#FAD33F",
            "#F7882B",
            "#529EE2",
            "#00CEDC",
            "#705DF9",
            "#F2637B",
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {},
          grid: {
            left: "3%",
            right: "3%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: year,
          },
          yAxis: [
            {
              type: "value",
              name: "单位：亿元",
            },
            {
              type: "value",
              axisLabel: {
                formatter: "{value} %",
              },
              alignTicks: true,
            },
          ],
          series: [
            {
              name: "第一产业",
              type: "bar",
              stack: "total",
              // label: {
              //   show: true,
              // },
              barWidth: 50,
              emphasis: {
                focus: "series",
              },
              data: data1,
            },
            {
              name: "第二产业",
              type: "bar",
              stack: "total",
              barWidth: 50,
              // label: {
              //   show: true,
              // },
              emphasis: {
                focus: "series",
              },
              data: data2,
            },
            {
              name: "第三产业",
              type: "bar",
              stack: "total",
              barWidth: 50,
              // label: {
              //   show: true,
              // },
              emphasis: {
                focus: "series",
              },
              data: data3,
            },
            {
              name: "第一产业同比增长",
              type: "line",
              yAxisIndex: 1,
              // label: {
              //   show: true,
              // },
              emphasis: {
                focus: "series",
              },
              data: data4,
            },
            {
              name: "第二产业同比增长",
              type: "line",
              yAxisIndex: 1,
              // label: {
              //   show: true,
              // },
              emphasis: {
                focus: "series",
              },
              data: data5,
            },
            {
              name: "第三产业同比增长",
              type: "line",
              yAxisIndex: 1,
              // label: {
              //   show: true,
              // },
              emphasis: {
                focus: "series",
              },
              data: data6,
            },
          ],
        };
        this.$echarts.init(this.$refs.in_L2).setOption(option);
        this.$loadingHide();
      });
    },

    get_in_H() {
      gross_industry_level().then(({ data }) => {
        this.options = this.removeEmptyChildren(data);
      });
    },

    get_in_HY() {
      this.$loadingShow();
      gross_industry({ industry_list: this.optionsList }).then(({ data }) => {
        let obj = data.data;
        let series = [];
        for (let key in obj) {
          series.push({
            name: key,
            type: "line",
            data: obj[key],
          });
        }
        this.$echarts.init(this.$refs.in_H).dispose();
        var option = {
          color: [
            "#529EE2",
            "#F7882B",
            "#00CEDC",
            "#FAD33F",
            "#F2637B",
            "#3EBB9F",
            "#705DF9",
            "#B3E5FF",
          ],
          tooltip: {
            trigger: "axis",
            order: "valueDesc",
          },
          legend: {},
          grid: {
            left: "3%",
            right: "3%",
            bottom: "3%",
            containLabel: true,
          },

          xAxis: {
            type: "category",
            boundaryGap: false,
            data: data.time,
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value} %",
            },
          },
          series: series,
        };
        this.$echarts.init(this.$refs.in_H).setOption(option);
        this.$loadingHide();
      });
    },
    companyChange(val) {
      if (val == false) {
        this.get_in_HY();
      }
    },

    removeEmptyChildren(data) {
      data.forEach((item) => {
        if (item.children && item.children.length === 0) {
          delete item.children;
        } else {
          this.removeEmptyChildren(item.children);
        }
      });
      return data;
    },
  },
};
</script>

<style lang="less" scoped>
.titles {
  font-size: 20px;
  font-weight: 550;
}
.titles_s {
  display: flex;
  justify-content: space-between;
  .titles_z {
    font-size: 20px;
    font-weight: 550;
    img {
      margin-right: 5px;
    }
  }
}

.invets {
  display: flex;
  justify-content: space-between;
  .invets_left,
  .invets_right {
    width: 49.5%;
    padding: 15px 20px;
    border-radius: 5px;

    background: #fff;
  }
}
.cy,
.hy {
  margin-top: 25px;
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;

  background: #fff;
}
</style>